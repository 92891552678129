#player {
	height: 100%;
	width: 100%;
}
.op-message-container{
	display:none
}
.op-controls-container{
	display: none !important;
}
.op-context-panel{
	display: none !important;
}