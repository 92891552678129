@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('../fonts/roboto-v30-latin-300.woff2') format('woff2'),
       url('../fonts/roboto-v30-latin-300.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/roboto-v30-latin-regular.woff2') format('woff2'),
       url('../fonts/roboto-v30-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('../fonts/roboto-v30-latin-500.woff2') format('woff2'),
       url('../fonts/roboto-v30-latin-500.woff') format('woff');
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Win half the scrolling war here */
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px;
}

/* For Button SVG Icons */
/* To keep in sync manually with palette (see /utils/site-theme.js) */
.offwhite {
  fill: #B0BEC5; /* Sync with blueGrey[200] */
}

.orange {
  fill: #F1A624; /* Sync with secondary.main */
}

/* TODO: inline these CSS using ThemeProvider hack, see ViolationPopup */
.nfz-popup {
  text-align: center;
}

.nfz-popup-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}

.nfz-popup-content {
  font-size: 12px;
  font-weight: bold;
  color: rgb(110, 110, 110);
}

.nfz-polygon-pulse {
  animation: 2s pulse 10;
}
